import aibSVG from "../../../assets/finance/aib.svg";
import anPostSVG from "../../../assets/finance/an-post.svg";
import boiSVG from "../../../assets/finance/boi.svg";
import creditUnionSVG from "../../../assets/finance/credit.svg";

export const financeData = {
  title: "Financing your Home Energy Upgrade",
  subtitle:
    "EnergyFix aims to help you on your journey, we have compiled a list of the finance options available to you.",
  description: [
    "Embarking on a home energy retrofit is an exciting endeavor that can breathe new life into your living space, enhance functionality, and boost your property's value. Whether you're looking to only do an energy retrofit or proceed with additional renovation works, the first question that often comes to mind is, '' How will I finance this project? ''",
    "Fortunately, there are a variety of home improvement finance options available to homeowners, each with its own set of advantages and considerations. In this guide, we will explore the diverse array of financing solutions at your disposal, helping you make informed decisions to turn your home improvement dreams into reality. Whether you're planning a small-scale update or a major renovation, understanding your financing options is the crucial first step on the path to transforming your home.",
  ],
  providers: {
    aib: {
      key: "aib",
      label: "AIB - Green Personal Loan",
      image: aibSVG,
      description:
        "With [[AIB's green personal loan](https://aib.ie/our-products/loans/green-personal-loan)], customers can avail of a green rate of 6.40% APR, the lowest personal loan rate currently available from the bank. For context, its current standard rate for personal loans is 8.95% so this is significantly lower. With this loan it's possible to borrow between €3,000 and €60,000 for up to ten years and you'll need to spend at least 50% of the loan on a green initiative (this can include the purchase of an electric car). AIB doesn't charge penalties should you decide to make extra repayments or if you'd like to repay your loan early, which can be a real help. And you don't need to be an existing customer of AIB to apply for this loan.",
    },
    boi: {
      key: "boi",
      label: "Bank of Ireland - Green Home Improvement Loan",
      image: boiSVG,
      description:
        "With [[Bank of Ireland's green home improvement loan](https://personalbanking.bankofireland.com/borrow/loans/green-home-improvement-loan/key-features/)] customers can obtain a variable rate of 6.50% APR. Loan amounts start from €2,000 and go all the way up to €65,000. Some of the advantages of choosing BOI's green loan include flexible repayment options, the ability to defer the first three months' repayments, as well as not needing to hold any savings against your loan. Similar to the other green loans available above, the work being undertaken must include a retrofitting activity such as installation of solar panels, insulation of any kind, and so on. [[You can find out more about this loan here.](https://personalbanking.bankofireland.com/borrow/loans/green-home-improvement-loan/key-features/)]",
    },
    creditUnion: {
      key: "creditUnion",
      label: "Chat to your Local Credit Union",
      image: creditUnionSVG,
      description: [
        "The Credit Union, Energeia and Irish-owned retrofit company House2Home have all recently teamed up to launch the CU Greener Homes Scheme, a one-stop shop for those looking to finance a retrofit. Grants of up to 40% are available as part of the scheme and are discounted upfront by House2Home. The SEAI provides a grant of 35% for homeowners who complete a deep retrofit project. The remaining 5% is provided as additional support by Energeia while participating Credit Unions are offering discounted loan rates from as low as 4.9% APR depending on the size of the work you're getting done. There are also a number of grants available for shallow retrofits. So whether you are considering just a new boiler or a deep retrofit, the CU Greener Homes Scheme can help. Head over to the [[CU Greener Homes Scheme website]( https://www.creditunion.ie/what-we-offer/loans/home-improvement-green/)] to learn more.",
        "Local Credit Unions also offer home renovation loans with an APRs of 5% - 8.6%. Reach out to your local credit union to enquire on their home renovation loan offerings.",
      ],
    },
    anPost: {
      key: "anPost",
      label: "An Post - Green Home Improvement Loan",
      image: anPostSVG,
      description:
        "Customers can also retrofit their homes with [[a green loan from An Post](https://www.anpost.com/Money/Low-Fixed-Rate-Loans/Green-Loans/Home-Energy-Improvement-Loans)]. It's possible to borrow from €5,000 to €75,000 and for a term of up to ten years. Rates from An Post start from as low as 4.9% APR. However, customers must be availing of an energy efficiency grant from the SEAI in order to apply. The loan applicant's homes must have been built prior to 2006. They must also submit an SEAI 'Declaration of Works' document with the loan application. With An Post the interest rate you pay is determined by your loan amount and credit profile. Take a look at the table below for further details.",
    },
  },
};
